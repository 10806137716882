<template>
  <v-card
    elevation="2"
    :class="
      `rounded-xl my-5 pa-2 mx-5 mx-xl-0 d-flex flex-column
    justify-space-between ${
      (index < indexByWitdh && !isExpanded) || (index > indexByWitdh - 1 && isExpanded)
        ? 'card-offer'
        : 'card-offer-second'
    }`
    "
    @click="
      () => {
        isMobile ? jobDetail(offer) : null;
      }
    "
  >
    <div class="card-content">
      <v-card-title>
        <v-row no-gutters justify="center">
          <v-col cols="12" align="start">
            <div class="title-card">
             {{ cutString(offer.title) }}
            </div>
          </v-col>
          <v-col cols="12" align="start">
            <div class="title-card-time mt-2" v-if="daysAgo != 0 && daysAgo != 1">
              {{ `Hace ${daysAgo} días` }}</div>
            <div class="title-card-time mt-2" v-else-if="daysAgo === 1">
              {{ `Hace ${daysAgo} día` }}</div>
            <div class="title-card-time mt-2" v-else>{{ `Hoy` }}</div>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-subtitle class="mt-1 d-flex flex-row pa-0 pl-3 mb-3 flex-wrap">
        <v-card class="d-flex flex-row card-chip align-center elevation-0">
          <v-icon color="pink" class="v-icon-color ml-sm-1">mdi-map-marker</v-icon>
          <p class="text-chip mb-0 pr-1 mx-2">{{ offer.sede }}</p>
        </v-card>
        <v-card class="ml-1 d-flex flex-row card-chip
        align-center elevation-0" v-if="offer.modality">
          <v-icon color="pink" class=" mr-md-1 ml-sm-1 v-icon-color">mdi-clock-outline</v-icon>
          <p class="text-chip mb-0 ml-1  mx-2">{{ offer.modality }}</p>
        </v-card>
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          xmlns="http://www.w3.org/2000/svg"
          class="mt-1 mb-2 ml-1"
          v-if="offer.disability === 'Si'"
        >
          <path
            d="M14.5 16L15.82 17.76C14.82 19.68 12.81 21 10.5 21C7.19 21 4.5 18.31 4.5 15C4.5 12.57 5.96 10.5 8.05 9.55L8.26 11.72C7.21 12.44 6.5 13.63 6.5 15C6.5 17.21 8.29 19 10.5 19C12.36 19 13.91 17.72 14.36 16H14.5ZM20.05 16.11L18.8 16.73L16 13H11.41L11.21 11H14.5V9H11L10.7 6C11.71 5.88 12.5 5.04 12.5 4C12.5 3.46957 12.2893 2.96086 11.9142 2.58579C11.5391 2.21071 11.0304 2 10.5 2C9.96957 2 9.46086 2.21071 9.08579 2.58579C8.71071 2.96086 8.5 3.46957 8.5 4V4.1L9.6 15H15L18.2 19.27L20.95 17.9L20.05 16.11Z"
            fill="#CE0F69"
          />
        </svg>
      </v-card-subtitle>
      <v-card-text class="d-none d-sm-block d-lg-none text-description">
        {{ `${text.slice(0, 120)}...` }}</v-card-text>
      <v-card-text class="d-none d-lg-block text-description">
        {{ `${text.slice(0, 140)}...` }}</v-card-text>
      <v-card-actions
        class="d-none d-sm-block mt-xl-10 btns-actions btns-actions-size"
        v-if="(index < indexByWitdh && !isExpanded) || (index > indexByWitdh - 1 && isExpanded)"
      >
        <v-btn rounded block class="card-button elevation-0" @click="knowMore(offer)">
          <span>Conoce más</span>
        </v-btn></v-card-actions
      >
      <v-card-actions class="d-none d-sm-block mt-xl-10 btns-actions btns-actions-size"
      v-if="isTablet">
        <v-btn rounded block class="card-button elevation-0" @click="knowMore(offer)">
          <span>Conoce más</span>
        </v-btn></v-card-actions
      >
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'CardOffer',
  props: {
    offer: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: Number,
      default: 0,
    },
    isExpanded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      secondPartTitle: '',
      text: '',
      daysAgo: 0,
      indexByWitdh: 0,
      numberWordsTitle: 0,
      isMobile: false,
      isTablet: false,
      window: {
        width: 1022,
        height: 700,
      },
    };
  },
  created() {
    this.extractText();
    this.offer.title = this.offer.title.charAt(0).toUpperCase() + this.offer.title
      .slice(1).toLowerCase();
    const sedesWords = this.offer.sede.split(' ');
    // eslint-disable-next-line prefer-destructuring
    this.offer.sede = sedesWords[0];
    this.calculateDaysAgo(this.offer.createdDate);
    this.resizeWitdh();
    window.addEventListener('resize', this.resizeWitdh);
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeWitdh);
  },
  methods: {
    knowMore(offer) {
      this.$gtag.event(`LPB_Btn_Conocemas_${offer.title}`, {
        event_category: 'Landing_CardConvocatoria',
        event_label: `Landing_CardConvocatoria LPB_Btn_Conocemas_${offer.title}`,
        value: 1,
      });
      this.jobDetail(offer);
    },
    jobDetail(offer) {
      this.$router.push({
        path: `/empleos/${offer.id}`,
      });
    },
    resizeWitdh() {
      this.window = {
        width: window.innerWidth,
        height: window.innerHeight,
      };
      if (this.window.width < 600) {
        this.isMobile = true;
      }
      if (this.window.width > 600 && this.window.width < 1919) {
        this.isTablet = true;
      }
      if (this.window.width >= 1920) {
        this.indexByWitdh = 4;
      }
    },
    extractText() {
      const parser = new DOMParser();
      const doc = parser.parseFromString(this.offer.content, 'text/html');
      const extractedText = doc.body.textContent;
      this.text = extractedText;
    },
    calculateDaysAgo(date) {
      const inital = new Date(date);
      const actual = new Date();
      const diference = actual - inital;
      this.daysAgo = Math.floor(diference / (1000 * 60 * 60 * 24));
    },
    cutString(inputString) {
      const characters = /[-./]/g;
      // eslint-disable-next-line quotes
      const charactersWithSpaces = inputString.replace(characters, (match) => `${match} `);
      return charactersWithSpaces;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./_app.scss";
$font-size: 20px;
$line-height: 1.5;
$lines-to-show: 2;
.title-card {
    color: $principal-white;
     display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  max-width: 350px;
  height: $font-size*$line-height*$lines-to-show; /* Fallback for non-webkit */
  margin: 0 auto;
  font-size: $font-size;
  line-height: $line-height;
  -webkit-line-clamp: $lines-to-show;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  }

.text-chip {
    font-size: 14px !important;
  }
.btns-actions-size {
  width: 95%;
}
.btns-actions {
  position: absolute;
  bottom: 0;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.text-description {
  font-family: $principal-font !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: $secondary-black;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: wrap;
  max-width: 320px;
  max-height: 160px;
  margin: 2px 0px 18px 0px;
}

.card-offer {
  position: relative;
  max-width: 340px;
  height: auto;
}

.card-offer-second {
  position: relative;
  max-width: 340px;
  height: auto;
}
.card-offer:hover {
  background: $background-color-gradient;

  .title-card {
    color: $principal-white;
  }
  .title-card-time {
    color: $principal-white;
  }

  .text-description {
    color: $principal-white;
  }

  .card-button {
    background: transparent;
    border: 3px solid $principal-white;
  }

  svg {
    path {
      fill: $principal-white !important;
    }
  }
  .card-chip {
    background: $principal-greyLight;
    .v-icon-color {
      color: $principal-white !important;
    }

    p {
      color: $principal-white !important;
    }
  }
}

.card-button {
  background: $background-color-gradient;
  span {
    font-family: $principal-font !important;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: $principal-white;
    text-transform: none;
  }
}
.card-chip {
  height: 36px;
  margin-right: 12px;
  background: $secondary-white;
  border-radius: 8px;
  p {
    font-family: $principal-font !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 47px;
    color: $secondary-black;
  }
}
.title-card {
  font-family: $principal-font !important;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  color: $title-card-color;
  word-break: normal;
  span {
    font-family: $principal-font !important;
    font-style: normal;
  }
}

.title-card-time {
  font-family: $principal-font !important;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.25px;
  word-break: normal;
  color: $secondary-fucsia;
}
@media (min-width: 600px) and (max-width: 1280px) {

  .card-offer-second:hover {
  background: $background-color-gradient;

  .title-card {
    color: $principal-white;
  }
  .title-card-time {
    color: $principal-white;
  }

  .text-description {
    color: $principal-white;
  }

  .card-button {
    background: transparent;
    border: 3px solid $principal-white;
  }

  svg {
    path {
      fill: $principal-white !important;
    }
  }
  .card-chip {
    background: $principal-greyLight;
    .v-icon-color {
      color: $principal-white !important;
    }

    p {
      color: $principal-white !important;
    }
  }
}
  .card-offer {
    max-width: 340px;
    height: 370px;
  }

  .card-offer-second {
    max-width: 340px;
    height: 370px;
  }

}

@media (max-width: 414px) {
  .title-card {
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    color: $title-card-color;
    word-break: normal;
  }
  .text-chip {
    font-size: 12px !important;
  }
}

@media (min-width: 960px) {
  .card-chip {
    margin-right: 4px;
    p {
      font-size: 16px;
    }
  }
  .card-content {
    overflow: hidden;
  }
  .text-description {
    max-height: 150px;
  }

  .card-offer {
    height: 370px;
    cursor:default
  }
  .card-offer-second {
    height: 370px;
    cursor:default
  }
}
@media (min-width: 1260px) {
  .card-offer-second {
    max-width: 360px;
    transition: height 0.8s ease;
    cursor:default
  }
  .card-offer-second:hover {
  background: $background-color-gradient;

  .title-card {
    color: $principal-white;
  }
  .title-card-time {
    color: $principal-white;
  }

  .text-description {
    color: $principal-white;
  }

  .card-button {
    background: transparent;
    border: 3px solid $principal-white;
  }

  svg {
    path {
      fill: $principal-white !important;
    }
  }
  .card-chip {
    background: $principal-greyLight;
    .v-icon-color {
      color: $principal-white !important;
    }

    p {
      color: $principal-white !important;
    }
  }
}
  .card-offer {
    max-width: 360px;
    height: 370px;
    transition: height 0.9s ease;
    overflow: hidden;
    cursor: default
  }
  .expanded {
    height: 450px;
  }
}

@media (min-width: 1466px) {
  .text-description {
    max-height: 200px;
    font-size: 16px;
  }
  .card-chip {
    margin-right: 12px;
  }
  .card-offer {
    height: 370px;
  }
}
@media (min-width: 1024px) {
  .title-card {
    font-weight: 700;
    font-size: 20px;
    line-height: 31px;
    color: $title-card-color;
    word-break: normal;
  }
  .text-chip {
    font-size: 14px !important;
  }
}

@media (min-width: 1450px) {
  .title-card {
    font-size: 24px;
  }
}
@media (min-width: 1903px){
  .card-offer-second {
    height: 190px;
    cursor:default
  }
}
</style>
