<template>
  <div>
    <section>
      <v-row no-gutters class="container-skeleton-slides" justify="center" align="center">
        <v-col cols="12" align="center" sm="6">
          <v-skeleton-loader
            class="mx-auto custom-skeleton-loader-avatar-width"
            max-width="300"
            type="avatar"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="12" sm="6">
          <v-row no-gutters>
            <v-col cols="12">
              <v-skeleton-loader class="my-2 mx-8 mx-sm-0" max-width="150" type="text"></v-skeleton-loader>
            </v-col>
            <v-col cols="12" class="my-3 container-custom-center-text">
              <v-skeleton-loader class="mx-8 mx-sm-0" max-width="800" type="text"></v-skeleton-loader>
            </v-col>
            <v-col cols="12" class="my-4">
              <v-skeleton-loader class="mx-8 mx-sm-0 pr-sm-12 pr-lg-0" max-width="420" type="text"></v-skeleton-loader>
              <v-skeleton-loader class="mx-8 mx-sm-0 pr-sm-12 pr-lg-0" max-width="420" type="text"></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </section>
    <section class="section-cards-offers">
      <v-row no-gutters class="mb-12">
        <v-col cols="12" class="container-section-cards-offers-title" align="center">
          <v-skeleton-loader class="mx-auto my-8" max-width="600" type="text"></v-skeleton-loader>
        </v-col>
        <v-col cols="12" class="my-5 mb-9">
          <v-row no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="11" md="12" xl="12" class="ml-lg-4">
              <v-chip-group mandatory show-arrows>
                <div class="d-flex flex-row container-skeleton-btns">
                  <v-skeleton-loader class="ml-6 mr-3" type="button"></v-skeleton-loader>
                  <v-skeleton-loader class="mx-3" type="button"></v-skeleton-loader>
                  <v-skeleton-loader class="mx-3" type="button"></v-skeleton-loader>
                  <v-skeleton-loader class="mx-3" type="button"></v-skeleton-loader>
                </div>
              </v-chip-group>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </v-col>
        <v-col cols="12" align="center">
          <v-row no-gutters>
            <v-col cols="12" sm="6" md="4" lg="3" class="container-skeleton-cards my-4">
              <v-skeleton-loader class="mx-3" type="button"></v-skeleton-loader>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" class="container-skeleton-cards my-4">
              <v-skeleton-loader class="mx-3" type="button"></v-skeleton-loader>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" class="container-skeleton-cards my-4">
              <v-skeleton-loader class="mx-3" type="button"></v-skeleton-loader>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" class="container-skeleton-cards my-4">
              <v-skeleton-loader class="mx-3" type="button"></v-skeleton-loader>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" class="container-skeleton-cards my-4">
              <v-skeleton-loader class="mx-3" type="button"></v-skeleton-loader>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" class="container-skeleton-cards my-4">
              <v-skeleton-loader class="mx-3" type="button"></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </section>
    <footer>
      <v-row no-gutters>
        <v-col cols="12" class="container-footer-btns" align="center">
          <v-skeleton-loader class="mb-5" type="button"></v-skeleton-loader>
        </v-col>
      </v-row>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'Skeletton',

  data() {
    return {
      isLoading: true,
      content: '',
    };
  },
  mounted() {
    setTimeout(() => {
      this.content = 'Contenido real cargado';
      this.isLoading = false;
    }, 2000);
  },
};
</script>

<style lang="scss" scoped>
@import "./_app.scss";

.container-skeleton-slides {
  height: 600px;
}
@media(min-width: 720px){
  .section-cards-offers {
  padding: 0% 3%;
}
}
</style>
