<template>
  <div>
    <div v-if="isLoading">
      <Skeletton />
    </div>
    <div v-else>
      <v-carousel
        height="auto"
        cycle interval="7000"
        class="carrucel-slide"
        hide-delimiter-background
        :light="valueCarousel === 1"
        @change="e => handleChangeCarrousel(e)"
      >
        <v-carousel-item>
          <v-sheet color="blueDark" class="carrucel-slide">
            <v-row
              no-gutters
              class="py-15 py-md-0 pt-md-15"
              justify="center"
              align="center"
            >
              <v-col cols="12" align="center" sm="5" lg="6" class="pt-md-12 pt-xl-0">
                <v-img
                  alt="convocatoria_slider"
                  contain
                  :src="require('../../../assets/newlanding/Evaluation/carrusel_convocatoria_1.png')"
                  :max-width="200"
                  class="d-sm-none"
                />
                <v-img
                  alt="convocatoria_slider"
                  contain
                  :src="require('../../../assets/newlanding/Evaluation/carrusel_convocatoria_1.png')"
                  :max-width="300"
                  class="d-none d-sm-block d-md-none"
                />
                <v-img
                  alt="convocatoria_slider"
                  contain
                  :src="require('../../../assets/newlanding/Evaluation/carrusel_convocatoria_1.png')"
                  :max-width="390"
                  class="d-none d-md-block d-lg-none"
                />
                <v-img
                  alt="convocatoria_slider"
                  contain
                  :src="require('../../../assets/newlanding/Evaluation/carrusel_convocatoria_1.png')"
                  :max-width="400"
                  class="d-none d-lg-block d-xl-none mt-4"
                />
                <v-img
                  alt="convocatoria_slider"
                  contain
                  :src="require('../../../assets/newlanding/Evaluation/carrusel_convocatoria_1.png')"
                  :max-width="490"
                  class="d-none d-xl-block mt-14"
                />
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" sm="6" class="content-titles d-sm-none" align="center">
                <p class="container-clip-title">¡Únete a nosotros!</p>
                <p class="mb-0 container-title">
                  El trabajo que mejor se adapte para ti.
                </p>
                <p class="mb-0 container-description">
                  Postula fácilmente a la posición que más te guste. Tenemos posiciones en: Atención al Cliente, Ventas,
                  Crosselling, Back Office y Redes Sociales
                </p>
              </v-col>
              <v-col cols="12" sm="6" lg="6"
              class="content-titles d-none d-sm-block pt-md-13 pt-xl-0" align="left">
                <p class="mb-0 container-clip-title">¡Únete a nosotros!</p>
                <p class="mb-0 container-title">
                  El trabajo que mejor se adapte para ti.
                </p>
                <p class="mb-0 container-description">
                  Postula fácilmente a la posición que más te guste. Tenemos posiciones en: Atención al Cliente, Ventas,
                  Crosselling, Back Office y Redes Sociales
                </p>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
          </v-sheet>
        </v-carousel-item>
        <v-carousel-item>
          <v-sheet color="transparent" class="carrucel-slide">
            <v-row no-gutters class="py-8 py-md-5 container-evaluation-section"
            align="center" justify="center">
              <v-col cols="12" align="center">
                <div class="title-evaluation mb-1 mb-sm-14 mt-sm-7"
                >Tu proceso de <span> evaluación</span></div>
              </v-col>
              <v-col cols="12" v-for="(step, i) in steps" :key="step.color" sm="6" md="3">
                <v-row no-gutters align="center" class="container-steps">
                  <v-col cols="12" align="center">
                    <v-row justify="center" align="center" no-gutters>
                      <v-col cols="12" :order="i % 2 !== 0 ? 'first' : 'last'" align="center"
                      class="d-none d-md-block">
                        <v-img
                          :alt="step.img"
                          contain
                          :src="require(`../../../assets/newlanding/Evaluation/${step.img}.png`)"
                          :max-width="220"
                          :height="200"
                          class="ml-lg-15"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-row no-gutters justify="center" align="center" class="px-8">
                          <v-col cols="4" align="center">
                            <div :class="`${step.color} titles`">{{ i + 1 }}</div>
                          </v-col>
                          <v-col cols="8" align="center">
                            <p :class="`items-step`">
                              <span class="items-step font-weight-bold">{{ step.text1 }}</span
                              ><span class="items-step">{{ step.text2 }}</span
                              ><span class="items-step font-weight-bold">{{ step.text3 }}</span>
                            </p>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
      <v-row no-gutters class="px-5 px-lg-10 py-15 px-xl-15 space-container-cards">
        <v-col cols="12" align="center" class="mb-8">
          <p class="title-offer-section"><span>Nuestras</span> convocatorias</p>
        </v-col>
        <v-col cols="12">
          <v-row no-gutters class="mx-sm-4 mr-5  ml-5 ml-md-4 mx-xl-0 space-container-buttons">
            <v-col cols="12" class="mt-2 mb-5" lg="12">
              <ButtonsCarrusel :buttons="buttons" :initialSelected="'0'"></ButtonsCarrusel>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row no-gutters align="center" justify="center">
            <v-data-iterator
              :items="jobsPublicados"
              hide-default-footer
              :page="page"
              :items-per-page.sync="itemsPerPage"
              class="data-iterator"
            >
              <template v-slot:default="props">
                <v-row no-gutters class="container-offer-cards"
                ref="scrollContainer" @scroll="handleScroll">
                  <v-col cols="12"
                  v-for="(offer, index) in props.items"
                  :key="offer.id" sm="6" md="4" :lg="isTablet ? 4 : 3" xl="3">
                    <CardOffer
                    :offer="offer"
                    :index="index"
                    :isExpanded="isExpanded"
                    />
                  </v-col>
                  <v-col cols="12" class="to-the-scroll"> </v-col>
                </v-row>
              </template>
              <template v-slot:no-data>
                <div class="container-nodata d-flex flex-column justify-center">
                  <v-icon size="100" class="d-none d-md-block mb-9">
                    mdi-text-box-search-outline
                  </v-icon>
                  <v-icon size="60" class="mb-12 d-md-none">
                    mdi-text-box-search-outline
                  </v-icon>
                  <p>
                    Actualmente no tenemos convocatorias. Vuelve a revisar en unas horas
                  </p>
                </div>
              </template>
              <template v-slot:footer>
                <v-pagination
                  total-visible="4"
                  v-model="page"
                  :class="`my-4 ${numberOfPages <= 3 ? 'hide-arrow' : ''} footer-item`"
                  :length="numberOfPages"
                ></v-pagination>
              </template>
            </v-data-iterator>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import ButtonsCarrusel from './ButtonsCarrusel.vue';
import CardOffer from './CardOffer.vue';
import Skeletton from './Skeletton.vue';
import {
  getAllJobs,
  getJobsTypification,
  getAllJobsByCategory
} from '../../../services/dynamo/postulants';

const CATEGORY_ALL = 'Todos';

export default {
  name: 'EvaluationLanding',
  components: {
    CardOffer,
    ButtonsCarrusel,
    Skeletton,
  },
  data() {
    return {
      window: {
        width: 1022,
        height: 700,
      },
      indexByWitdh: 0,
      isMobile: false,
      isTablet: false,
      isLoading: true,
      isExpanded: false,
      dataOffers: [],
      itemsPerPage: 0,
      page: 1,
      itemsFooter: 0,
      valueCarousel: '',
      buttons: [],
      categories: [],
      currentCategory: CATEGORY_ALL,
      steps: [
        {
          color: 'orange-step',
          text1: 'Postula y registra tus datos. ',
          text2: 'Realiza las evaluaciones que te enviaremos.',
          text3: '',
          img: 'carrusel_convocatoria_2_4',
        },
        {
          color: 'green-step',
          text1: '',
          text2: 'Revisaremos tus resultados y nos comunicaremos ',
          text3: 'contigo.',
          img: 'carrusel_convocatoria_2_1',
        },
        {
          color: 'blue-step',
          text1: '',
          text2: 'Empieza tu etapa de ',
          text3: 'capacitación.',
          img: 'carrusel_convocatoria_2_3',
        },
        {
          color: 'pink-step',
          text1: '',
          text2: 'Inicia tu gestión con ',
          text3: 'nosotros.',
          img: 'carrusel_convocatoria_2_5',
        },
      ],
      jobsPublicados: [],
      totalPages: 0,
      switchSelect: 0,
    };
  },
  computed: {
    numberOfPages() {
      return this.totalPages;
    },
    widthComputed() {
      if (this.window.width > 1400) return 3;
      return 4;
    },
  },
  async created() {
    this.window = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
    await this.getJobs();
    this.initializeButtons();
    this.resizeWitdh();
    window.addEventListener('resize', this.resizeWitdh);
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeWitdh);
  },
  watch: {
    async page(newPage) {
      if (this.currentCategory === CATEGORY_ALL) {
        return;
      } else {
        await this.handleGetJobsByCategory(this.currentCategory, newPage);
      }
    },
  },
  methods: {
    initializeButtons() {
      this.buttons = [{
        title: CATEGORY_ALL,
        action: () => this.filterByButtons(CATEGORY_ALL),
        id: '0',
      }];

      this.categories.forEach((category, index) => {
        this.buttons.push({
          title: category.name,
          action: () => this.filterByButtons(category.name),
          id: (index + 1).toString(),
        });
      });
    },
    resizeWitdh() {
      this.window = {
        width: window.innerWidth,
        height: window.innerHeight,
      };
      if (this.window.width < 600) {
        this.isMobile = true;
      }
      if (this.window.width > 600 && this.window.width < 1439) {
        this.isTablet = true;
      }
      if (this.window.width > 1440 && this.window.width < 1919) {
        this.isComputer = true;
      }
      if (this.window.width >= 1920) {
        this.indexByWitdh = 4;
      }
    },
    handleScroll() {
      const scrollContainerElement = this.$refs.scrollContainer;
      const scrollPosition = scrollContainerElement.scrollTop;

      if (scrollPosition > 0) {
        this.isExpanded = true;
      } else {
        this.isExpanded = false;
      }
    },
    async getJobs() {
      try {
        this.isLoading = true;
        const typification = await getJobsTypification();
        const jobsData = await getAllJobs();

        this.categories = typification?.categories
        this.jobsPublicados = jobsData?.data
        this.dataOffers = jobsData?.data
        this.itemsFooter = jobsData.total;
        this.itemsPerPage = jobsData.total;
        this.totalPages = jobsData.totalPages;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    handleChangeCarrousel(e) {
      this.valueCarousel = e;
    },
    async handleGetJobsByCategory(category, page = 1) {
      try {
        const dataByCategory = await getAllJobsByCategory(category, page)
        this.jobsPublicados = dataByCategory?.data
        this.dataOffers = dataByCategory?.data
        this.itemsFooter = dataByCategory.total;
        this.totalPages = dataByCategory.totalPages;
      } catch (error) {
        console.log(error);
      }
    },
    async filterByButtons(category) {
      this.page = 1;
      this.currentCategory = category;
      if (category === CATEGORY_ALL) {
        this.getJobs();
      } else {
        await this.handleGetJobsByCategory(category)
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./_app.scss";
.data-iterator {
  width: 100%;
}

.item-footer-selected {
  background: $background-color-gradient !important;
  p {
    color: $principal-white !important;
  }
}

.item-footer- {
  background-color: none;
  p {
    color: $secondary-black;
  }
}
.letter-item-footer {
  cursor: pointer;
  p {
    font-family: $principal-font !important;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: $secondary-black;
  }
}
.container-nodata {
  height: 300px;
  display: flex;
  align-items: center;
  padding: 0% 4%;
  text-align: center;
  i {
    color: $secondary-black !important;
  }
  p {
    font-family: $principal-font !important;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
  }
}

.title-switch {
  font-family: $principal-font !important;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: $principal-black;
}

.title-offer-section {
  font-family: $principal-font !important;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 34px;
  text-align: center;

  /* Primarios/azul black */

  color: $principal-blue-black;
  span {
    font-family: $principal-font !important;
    font-weight: 600;
  }
}
.card-switch {
  width: 197px;
  font-size: 12px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 16px !important;
  font-family: $principal-font !important;
  font-weight: 500;
  display: flex;
}

.size-item-switch {
  border-radius: 16px !important;
  width: 50%;
  height: 43px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $principal-font !important;
}

.actived-switch {
  background-color: $principal-green;
  color: white;
  cursor: pointer;
}
.inactived-switch {
  cursor: pointer;
  background-color: transparent;
}
.carrucel-slide {
  height: 645px !important;
}
.container-clip-title {
  margin-top: 40px;
  font-family: $principal-font !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  /* or 35px */

  /* Primarios/Fucsia */

  color: $principal-white;
}
.container-title {
  font-family: $principal-font !important;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  text-align: center;
  max-width: 326px;
  /* or 56px */
  color: $principal-yellow;
}

.container-description {
  font-family: $principal-font !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  width: 326px;
  margin: 20px 0px 0px 0px;
  color: $principal-white;
}

.container-button {
  margin: 30px 0px 0px 0px;
  border-radius: 8px;
  span {
    text-transform: capitalize;
  }
}

.title-evaluation {
  font-family: $principal-font !important;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 120%;
  color: $principal-blue;
  span {
    font-family: $principal-font !important;
    font-weight: 600;
  }
}
.titles {
  font-family: $principal-font !important;
  font-size: 120px;
  font-weight: 700;
}

.container-steps {
  height: 135px;
}
.items-step {
  font-family: $principal-font !important;
  color: $secondary-blue;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}
.orange-step {
  color: $step-orange;
}

.green-step {
  color: $step-cian;
}

.blue-step {
  color: $step-blue;
}

.pink-step {
  color: $step-pink;
}

@media (min-width: 600px) {
  .title-offer-section {
    font-size: 42px;
  }
  .carrucel-slide {
    height: 550px !important;
  }
  .title-evaluation {
    font-size: 36px;
  }
  .items-step {
    font-size: 18px;
    width: 226px;
  }

  .left {
    text-align: end;
  }

  .rigth {
    text-align: start;
  }
  .container-title {
    font-size: 46.2645px;
    text-align: left;
    max-width: 448px;
  }
  .container-description {
    font-size: 20px;
    text-align: left;
    max-width: 689.76px;
  }

  .content-btn {
    text-align: left;
  }
  .container-button {
    margin: 30px 0px 0px 0px;
    border-radius: 8px;
    span {
      text-transform: capitalize;
    }
  }
}
@media (min-width: 956px) {
  .container-nodata {
    height: 500px;
    padding: 0% 20%;
    p {
      font-size: 24px;
    }
  }

  .to-the-scroll {
    margin-top: 40px;
    height: 30px;
  }
  .container-offer-cards::-webkit-scrollbar {
    width: 12px;
    background-color: rgb(226, 223, 223);
  }
  .container-offer-cards::-webkit-scrollbar-thumb {
    background-color: $secondary-fucsia;
    border-radius: 16px;
  }

  .card-switch {
    width: 200px;
    font-size: 14px;
  }
  .title-switch {
    font-size: 16px;
  }
  .container-clip-title {
    margin-top: 0px;
    font-size: 25px;
  }
  .left {
    margin: 0px 0px 0px 9px !important;
  }

  .rigth {
    margin: 0px 9px 0px 0px !important;
  }
  .container-evaluation-section {
    padding-left: 10%;
  }
  .container-title {
    width: 448px !important;
  }
  .container-description {
    width: 690px;
  }
}

@media (min-width: 960px) {
  .container-description {
    width: 500px;
  }
  .items-step {
    font-size: 18px;
    width: 160px;
  }
  .container-evaluation-section {
    padding-left: 0%;
  }
}
@media (min-width: 1160px) {
  .items-step {
    width: 226px;
  }
  .container-evaluation-section {
    padding: 0%;
    padding-left: 0%;
  }
}

@media (min-width: 1267px) {
  .space-container-buttons {
    padding-right: 3% !important;
  }
  .container-description {
    width: 656px;
  }
}

@media (min-width: 1280px) {
  .title-evaluation {
    font-size: 48px;
  }
  .items-step {
    font-size: 22px;
  }
  .container-description {
    width: 550px;
  }
}
@media (min-width: 1700px) {
  .space-container-buttons {
    padding-right: 1% !important;
  }
  .space-container-cards {
    padding-left: 6% !important;
  }
  .carrucel-slide {
    height: 610px !important;
  }
}

@media (min-width: 1850px) {
  .container-offer-cards {
    overflow-y: scroll;
    height: 650px;
  }
  .container-description {
    width: 650px;
  }
  .space-container-buttons {
    padding-right: 7% !important;
  }
}
</style>
