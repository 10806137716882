<template>
  <div>
    <div class="container">
  <div class="buttons-wrapper">
    <v-btn
        v-for="item in buttons"
        :key="item.title"
        :class="`button-chip elevation-0 mx-2 d-flex align-center justify-center ${selected !== item.id ? 'button-chip--inactive': 'button-chip--active'}`"
        outlined
        color="pink"
        @click="()=>{item.action(); selected = item.id}"
      >
        <p class="mb-0">{{ item.title }}</p></v-btn
      >
  </div>
</div>
  </div>
</template>

<script>
export default {
  name: 'ButtonsCarrusel',
  props: {
    buttons: {
      type: Array,
    },
    initialSelected: {
      type: String,
      default: '0',
    },
  },
  data() {
    return { selected: this.initialSelected };
  },
};
</script>

<style lang="scss" scoped>
@import "./_app.scss";

.container {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  height: 100%;
  margin: 0px;
  padding: 0px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.buttons-wrapper {
  display: flex;
}

.button {
  margin-right: 10px;
  padding: 0px 50px;
}

.container::-webkit-scrollbar {
  display: none;
}

.button-chip--active {
  background-color: $secondary-fucsia !important;
  p {
    color: $principal-white;
  }
}

.button-chip--inactive {
  background-color: transparent !important;
  p {
    color: $secondary-fucsia;
  }
}
.button-chip {
  cursor:pointer;
  border-radius: 16px;
  width: 140px;
  height: 43px;
  border: 1.5px solid $secondary-fucsia;
  border-radius: 16px;
  p {
    font-family: $principal-font !important;
    text-transform: capitalize;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 34px;
  }
}

@media (min-width: 1023px){
  .buttons-wrapper {
  display: flex;
  width: 100%;
}
.container {
  max-width: 1000px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  height: 100%;
  margin: 0px;
  padding: 0px;
}
}
</style>
